import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Instagram, MapPin, Users, Award, Globe } from 'lucide-react';
import PrivacyPolicy from './PrivacyPolicy';
import Eula from './Eula';
import ResetPasswordForm from './ResetPassword';
import logo from './geo_logo.png';
import './App.css';
import Footer from './Footer';

interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

function FeatureCard({ icon, title, description }: FeatureCardProps) {
  return (
    <div className="p-6 bg-gray-800 rounded-lg">
      <div className="mb-4 text-4xl">{icon}</div>
      <h3 className="mb-2 text-xl font-semibold">{title}</h3>
      <p>{description}</p>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/eula" element={<Eula />} />
        <Route path="/reset-password" element={<ResetPasswordForm />} />
        <Route path="/" element={
          <div className="flex flex-col min-h-screen text-white bg-black">
            <nav className="p-4 bg-black">
              <div className="container flex items-center justify-between mx-auto">
                {/* <img src={logo} alt="Geo logo" className="w-12 h-12" /> */}
                <div className="space-x-4">
                  {/* <Link to="/about" className="text-white hover:text-gray-300">About</Link>
                  <Link to="/features" className="text-white hover:text-gray-300">Features</Link>
                  <Link to="/contact" className="text-white hover:text-gray-300">Contact</Link> */}
                </div>
              </div>
            </nav>
            
            <main className="container flex-grow px-4 py-2 mx-auto">
              <div className="text-center">
                <img src={logo} alt="Geo logo" className="mx-auto mb-6 w-34 h-36 animate-pulse" />
                
                <h1 className="mb-4 text-4xl font-bold md:text-5xl">Welcome to Gēo</h1>
                <p className="mb-8 text-xl">Discover and share what's happening around you.</p>
                
                <div className="max-w-2xl mx-auto mb-8">
                  <p className="mb-4 text-lg">
                    Gēo is a location-based social network that connects you with your community and beyond.
                    See real-time stories and events happening near you, and share your own experiences with the world.
                  </p>
                  <p className="mb-4 text-lg">
                    Our mission is to unite people by showing the pulse of their local area and letting them explore the globe.
                    With Gēo, you'll never miss out on what's happening around you or around the world.
                  </p>
                </div>
                
                <div className="grid grid-cols-1 gap-8 mb-12 md:grid-cols-2">
                  <FeatureCard icon={<Users />} title="Friends" description="Follow and connect with friends to see their stories." />
                  <FeatureCard icon={<MapPin />} title="Near You" description="Discover stories within a 5-mile radius, from users you may or may not follow." />
                  <FeatureCard icon={<Award />} title="Rankings" description="Compete for top user and city rankings, earning badges as you go." />
                  <FeatureCard icon={<Globe />} title="Explore" description="Use the map view to explore activities and heatmaps around the globe." />
                </div>
                
                <div className="mb-8 space-y-4">
                  <a href="https://testflight.apple.com/join/qOoY53P1" target="_blank" rel="noopener noreferrer" className="inline-block px-6 py-2 text-black transition duration-300 bg-white rounded-full shadow-lg hover:bg-gray-100">
                    Join TestFlight Beta
                  </a>
                  {/* <div className="flex justify-center space-x-4">
                    <Link to="/eula" className="px-6 py-2 text-white transition duration-300 bg-gray-800 rounded-full shadow-lg hover:bg-gray-700">EULA</Link>
                    <Link to="/privacy-policy" className="px-6 py-2 text-white transition duration-300 bg-gray-800 rounded-full shadow-lg hover:bg-gray-700">Privacy Policy</Link>
                  </div> */}
                </div>

                <a href="https://www.instagram.com/geoapp.pr?igsh=MTdvb3FqNG83MWE0Zw==" target="_blank" rel="noopener noreferrer" className="inline-flex items-center text-white hover:text-gray-300">
                  <Instagram className="w-6 h-6 mr-2" />
                  Follow us on Instagram
                </a>
              </div>
            </main>
            
             <Footer />
          </div>
        } />
      </Routes>
    </Router>
  );
}

export default App;