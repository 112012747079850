import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4 bg-black">
      <h2 className="mb-4 text-2xl font-bold text-white sm:text-3xl">Privacy Policy</h2>
      <div className="w-full max-w-xl text-sm text-white sm:text-base">

        <h3 className="mb-2 text-lg font-semibold text-white">Introduction</h3>
        <p className="mb-4 text-justify">
          At Gēo, we are committed to maintaining the trust and confidence of our users. In this Privacy Policy, we provide detailed information on when and why we collect your personal information, how we use it, and how we keep it secure.
        </p>

        <h3 className="mb-2 text-lg font-semibold text-white">Information We Collect</h3>
        <p className="mb-4 text-justify">
          We may collect and process the following data about you: name, email address and other information you provide when you register for an account. We may also collect information about your use of our app and services, including your location, and device information.
        </p>

        <h3 className="mb-2 text-lg font-semibold text-white">How We Use Your Information</h3>
        <p className="mb-4 text-justify">
          We use the information we collect about you in various ways, including to: provide, maintain, and improve our app and services; provide and deliver the information, products, and services you request; and to send you technical notices, updates, security alerts, and support and administrative messages.
        </p>

        <h3 className="mb-2 text-lg font-semibold text-white">Data Storage and Security</h3>
        <p className="mb-4 text-justify">
          We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic, and managerial procedures to safeguard and secure the information we collect online.
        </p>

        <h3 className="mb-2 text-lg font-semibold text-white">Your Rights</h3>
        <p className="mb-4 text-justify">
          You have the right to access, correct, delete, or transfer your personal data that we hold. You also have the right to complain to a data protection authority about our collection and use of your personal data.
        </p>

        <h3 className="mb-2 text-lg font-semibold text-white">Changes to Our Privacy Policy</h3>
        <p className="mb-4 text-justify">
          We may update this Privacy Policy from time to time in response to changing legal, technical, or business developments. When we update our Privacy Policy, we will take appropriate measures to inform you.
        </p>

        <h3 className="mb-2 text-lg font-semibold text-white">Contact Us</h3>
        <p className="mb-4 text-justify">
          If you have any questions about this Privacy Policy, please contact us at janfaris@gēo.com.
        </p>
        
      </div>
    </div>
  );
};

export default PrivacyPolicy;
