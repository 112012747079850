import React from 'react';
import { Link } from 'react-router-dom';
import { Instagram, Mail } from 'lucide-react';

function Footer() {
  return (
    <footer className="py-8 text-center text-white bg-black">
      <div className="container px-4 mx-auto">
        <div className="grid grid-cols-1 gap-8 mb-8 md:grid-cols-3">
          <div>
            <h3 className="mb-4 text-lg font-semibold">About Gēo</h3>
            <p className="text-sm">Gēo is a location-based social network that connects you with your community and beyond.</p>
          </div>
          <div>
            <h3 className="mb-4 text-lg font-semibold">Legal</h3>
            <ul className="space-y-2">
              {/* <li><Link to="/terms-of-service" className="text-sm hover:underline">Terms of Service</Link></li> */}
              <li><Link to="/privacy-policy" className="text-sm hover:underline">Privacy Policy</Link></li>
              {/* <li><Link to="/cookie-policy" className="text-sm hover:underline">Cookie Policy</Link></li> */}
              <li><Link to="/eula" className="text-sm hover:underline">EULA</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="mb-4 text-lg font-semibold">Connect</h3>
            <ul className="space-y-2">
              {/* <li><Link to="/contact" className="text-sm hover:underline">Contact Us</Link></li> */}
              <li><a href="https://www.instagram.com/geoapp.pr" target="_blank" rel="noopener noreferrer" className="inline-flex items-center text-sm hover:underline">
                <Instagram className="w-4 h-4 mr-1" /> Instagram
              </a></li>
              {/* <li><Link to="/faq" className="text-sm hover:underline">FAQ</Link></li> */}
              <li><a href="mailto:janfaris@xn--go-wma.com" className="inline-flex items-center text-sm hover:underline">
                <Mail className="w-4 h-4 mr-1" /> Support
              </a></li>
            </ul>
          </div>
        </div>
        <div className="text-sm">
          <p>&copy; {new Date().getFullYear()} Gēo by Jan. All rights reserved.</p>
          {/* <p className="mt-2">
            Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S. and other countries. 
            App Store is a service mark of Apple Inc.
          </p> */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;