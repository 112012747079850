import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient';

const ResetPasswordForm: React.FC = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isValidToken, setIsValidToken] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      const token = new URLSearchParams(window.location.search).get('token');
      if (!token) {
        //setMessage('Invalid reset link');
        setIsLoading(false);
        return;
      }
      try {
        const { error } = await supabase.auth.verifyOtp({
          token_hash: token,
          type: 'recovery',
        });
        if (error) throw error;
        setIsValidToken(true);
      } catch (error) {
        setMessage('Invalid or expired reset link');
        console.error('Error verifying token:', error);
      } finally {
        setIsLoading(false);
      }
    };
    verifyToken();
  }, []);

  const resetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage('');
    if (!isValidToken) {
      setMessage('Invalid reset link');
      return;
    }
    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }
    try {
      const { error } = await supabase.auth.updateUser({ password: password });
      if (error) throw error;
      setMessage('Password updated successfully. Redirecting to the Gēo...');
      setTimeout(() => {
        window.location.href = 'titan://login';
      }, 2000);
    } catch (error) {
      setMessage('An error occurred while resetting the password');
      console.error('Error resetting password:', error);
    }
  };

  if (isLoading) {
    return <div className="flex items-center justify-center min-h-screen text-white bg-black">Loading...</div>;
  }

  if (!isValidToken) {
    return (
      <div className="flex items-center justify-center min-h-screen text-white bg-black">
        <div className="text-center">
          <h2 className="mb-4 text-2xl font-bold">Invalid Reset Link</h2>
          <p>{message}</p>
          <button
            onClick={() => window.location.href = 'titan://login'}
            className="px-4 py-2 mt-4 text-white bg-blue-600 rounded-full hover:bg-blue-700"
          >
            Open App
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center min-h-screen px-4 py-12 bg-black sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-3xl font-extrabold text-center text-white">Create a new password</h2>
        <p className="mt-2 text-sm text-center text-gray-400">
          Saving a new password will sign you out on all your devices.
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="px-4 py-8 bg-gray-900 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={resetPassword}>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-300">
                New Password
              </label>
              <div className="relative mt-1">
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  required
                  className="block w-full px-3 py-2 text-white placeholder-gray-500 bg-gray-800 border border-gray-700 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm text-gray-400"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? "Hide" : "Show"}
                </button>
              </div>
            </div>

            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-300">
                Confirm New Password
              </label>
              <div className="relative mt-1">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  required
                  className="block w-full px-3 py-2 text-white placeholder-gray-500 bg-gray-800 border border-gray-700 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm text-gray-400"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? "Hide" : "Show"}
                </button>
              </div>
            </div>

            <div className="text-sm text-gray-400">
              <ul className="list-disc list-inside">
                <li>Minimum 8 characters, maximum 20</li>
                <li>Must include upper and lower case letters</li>
                <li>Must include at least one number or one of the following characters: !@#$%</li>
              </ul>
            </div>

            <div>
              <button
                type="submit"
                className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Save password
              </button>
            </div>
          </form>

          {message && (
            <div className={`mt-6 text-sm text-center ${message.includes('successfully') ? 'text-green-400' : 'text-red-400'}`}>
              {message}
            </div>
          )}

          <div className="mt-6 text-center">
            <a href="titan://login" className="text-sm text-blue-400 hover:text-blue-300">
              Go to sign-in
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;