import React from 'react';

const Eula = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4 bg-black">
      <h2 className="mb-4 text-2xl font-bold text-white sm:text-3xl">End-User License Agreement (EULA)</h2>
      <div className="w-full max-w-xl text-sm text-white sm:text-base">
        
        <h3 className="mb-2 text-lg font-semibold text-white">Introduction</h3>
        <p className="mb-4 text-justify">
         Welcome to Gēo! This End-User License Agreement ("Agreement") is a legal agreement between you (either an individual or a single entity) and Gēo ("Company", "we", "us", or "our"). It governs your use of our mobile application ("App") and any related services provided by us.

By downloading, installing, or using the App, and accessing our services, you agree to be bound by the terms of this Agreement. If you do not agree to the terms of this Agreement, do not download, install, or use the App.
        </p>

        <h3 className="mb-2 text-lg font-semibold text-white">License</h3>
        <p className="mb-4 text-justify">
          The Company grants you a revocable, non-exclusive, non-transferable, limited license to download, install, and use the App strictly in accordance with the terms of this Agreement.
        </p>

        <h3 className="mb-2 text-lg font-semibold text-white">Content Policy</h3>
        <p className="mb-4 text-justify">
          User-Generated Content: Users may upload, share, and view video and image content ("User Content"). You are solely responsible for the User Content you share.
Prohibited Content: You agree not to upload, share, or otherwise disseminate any content that is illegal, offensive, defamatory, pornographic, harassing, threatening, or otherwise objectionable. This includes content that infringes upon intellectual property rights, privacy rights, or public rights.
Location Sharing: The App may provide features enabling location sharing. You must exercise caution and good judgment when sharing your location.
        </p>

        <h3 className="mb-2 text-lg font-semibold text-white">Privacy Policy</h3>
        <p className="mb-4 text-justify">
          Your use of the App is also governed by our Privacy Policy, which outlines how we collect, use, and protect your personal information.
        </p>

        <h3 className="mb-2 text-lg font-semibold text-white">Modifications to Application</h3>
        <p className="mb-4 text-justify">
          The Company reserves the right to modify, suspend or discontinue, temporarily or permanently, the App or any service to which it connects, with or without notice and without liability to you.
        </p>

        <h3 className="mb-2 text-lg font-semibold text-white">Term and Termination</h3>
        <p className="mb-4 text-justify">
          This Agreement shall remain in effect until terminated by you or the Company. The Company may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.
        </p>

        <h3 className="mb-2 text-lg font-semibold text-white">Severability</h3>
        <p className="mb-4 text-justify">
          If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.

        </p>

        <h3 className="mb-2 text-lg font-semibold text-white">Amendments to this Agreement</h3>
        <p className="mb-4 text-justify">
          The Company reserves the right, at its sole discretion, to modify or replace this Agreement at any time.
        </p>

        <h3 className="mb-2 text-lg font-semibold text-white">Limitation of Liability</h3>
        <p className="mb-4 text-justify">
          To the fullest extent permitted by applicable law, in no event will the Company, its affiliates, officers, directors, employees, agents, suppliers, or licensors be liable to any person for any indirect, incidental, special, punitive, cover, or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use of content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if the Company has been advised as to the possibility of such damages or could have foreseen such damages. The limitations and exclusions also apply if this remedy does not fully compensate you for any losses or fails of its essential purpose.
        </p>

        <h3 className="mb-2 text-lg font-semibold text-white">Contact Information</h3>
        <p className="mb-4 text-justify">
          If you have any questions about this Agreement, please contact us at janfaris@gēo.com.
        </p>
      </div>
    </div>
  );
};

export default Eula;
